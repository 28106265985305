<template>
  <div class="del-wrap">
    <el-dialog title="关闭提醒"
               :visible="wholeDialogVisible"
               :close-on-click-modal="false"
               :close-on-press-escape="false"
               :show-close="false"
               width="500px"
               center>
      <p class="content">请确认要关闭全局提现开启？</p>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="cancleDialog">取 消</el-button>
        <el-button type="primary"
                   @click="submitDialog">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    wholeDialogVisible: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    cancleDialog () {
      this.$emit('cancleDialog', 'wholeDialogVisible')
    },
    submitDialog () {
      this.$emit('submitWholeDialog', 0)
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  text-align: center;
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
.del-wrap /deep/ button.el-button.el-button--default {
  width: 85px;
  height: 32px;
}
</style>